import React from 'react'
import iso from "../Images/Certificates/iso.jpg"
import iatf from "../Images/Certificates/iatf.jpg"
import zed from "../Images/Certificates/qcized.jpg"
import Layout from "../Components/reusable/Layout"
import Banner from "../Components/reusable/Banner"
function Achievements() {
    return (
      <Layout>
        <Banner title="Achievements" />
        <div className="section">
          <div className="content-wrap">
            <div className="container">
              <div className="row my-5">
                <div className="col-md-4 ">
                  <div
                    className="rs-icon-info-1"
                    style={{ paddingBottom: "3rem" }}
                  >
                    <div className="info-icon">
                      <img src={iso} alt="" width="100%" />
                    </div>
                    <div className="info-text">
                      <h4 className="text-black mb-2">ISO Certificate</h4>
                      <p style={{ marginBottom:"44px"}}>
                        ISO certification certifies that a management system,
                        manufacturing process, service, or documentation
                        procedure has all the requirements for standardization
                        and quality assurance.
                      </p>
                      <a href={iso} className="btn btn-secondary">
                        View Certificate
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 ">
                  <div
                    className="rs-icon-info-1"
                    style={{ paddingBottom: "3rem" }}
                  >
                    <div className="info-icon">
                    <img src={iatf} alt="" width="100%" />
                    </div>
                    <div className="info-text">
                      <h4 className="text-black mb-2">IATF 16949</h4>
                      <p style={{ marginBottom:"44px"}}>
                        IATF 16949:2016 (1st edition) represents an innovative
                        document, given the strong orientation to the customer,
                        with inclusion of a number of consolidated previous
                        customer specific requirements.
                      </p>
                      <a href={iatf} className="btn btn-secondary">
                        View Certificate
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="rs-icon-info-1" style={{ paddingBottom: "3rem" }}>
                    <div className="info-icon">
                    <img src={zed} alt="" width="100%" />
                    </div>
                    <div className="info-text">
                      <h4 className="text-black mb-2">QCI ZED</h4>
                      <p>
                        We should manufacture goods in such a way that they
                        carry zero defect and that our exported goods are never
                        returned to us. We should manufacture goods with zero
                        effect that they should not have a negative impact on
                        the environment
                      </p>
                      <a href={zed} className="btn btn-secondary">
                        View Certificate
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
}

export default Achievements
